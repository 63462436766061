<div class="container-fluid">
  <div class="border-bottom pt-6">
      <div class="row align-items-center">
          <div class="col-sm col-12">
              <h1 class="h2 ls-tight">Assignments</h1>
          </div>
          <div class="col-sm-auto col-12 mt-4 mt-sm-0">
              <div class="hstack gap-2 justify-content-sm-end">
                  <button class="submitForm btn btn-sm px-3 mx-5 d-flex align-items-center btn btn-sm btn-primary" (click)="onAdd()">
                      <i id="add" class="bi bi-plus-circle-dotted me-2"></i>
                      Add New
              </button>
              </div>
          </div>
      </div>
      <ul class="nav nav-tabs">         
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/assignments/active-assignments">Active</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/assignments/inactive-assignments">Inactive</a>
            </li>
            <li *ngIf="user?.role_uuid === ROLES?.CANDIDATE" class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/assignments/assignment-requests">Requests</a>
            </li>
      </ul>
      <router-outlet></router-outlet>
  </div>
</div>