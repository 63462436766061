import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { permissionConstant, roleConstant } from '../app.constant';
import { User } from '../app.model';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  @Input() isSideBarOpen: boolean | undefined;

  user: User;
  visible: Boolean = false;
  showDropUp: Boolean = false;
  mobilesettingshowDropDown: Boolean = false;
  permissionsMenu: any = [];
  showReportSubmenu: Boolean = false;

  constructor(
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
  ) {
    this.user = this.authService.userValue;
  }

  async ngOnInit(): Promise<void> {
    this.permissionsMenu.push({
      path: '/dashboard',
      name: 'Dashboard',
      icon: 'bi bi-grid',
    });
    const d: any = await localStorage.getItem('permissions');
    this.dataSharedService.permissionData = JSON.parse(d);

    this.dataSharedService.permissionData.forEach((element: any) => {
      switch (element.permission) {
        case permissionConstant.AGENCIES:
          this.permissionsMenu.push({
            path: '/agencies',
            name: element.permission,
            icon: 'bi bi-building',
          });
          break;
        case permissionConstant.CANDIDATES:
          if (this.user.role_uuid === roleConstant.AGENCY) {
            this.permissionsMenu.push({
              path: '/candidates',
              name: element.permission,
              icon: 'bi bi-person',
            });
          }
          break;
        case permissionConstant.JOBS:
          this.permissionsMenu.push({
            path: '/jobs',
            name: element.permission,
            icon: 'bi bi-briefcase',
          });
          break;
        case permissionConstant.TIMESHEETS:
          this.permissionsMenu.push({
            path: '/timesheets',
            name: element.permission,
            icon: 'bi bi-layout-text-window-reverse',
          });
          break;
        case permissionConstant.CLIENTS:
          this.permissionsMenu.push({
            path: '/clients',
            name: element.permission,
            icon: 'bi bi-people',
          });
          break;
        case permissionConstant.ASSIGNMENTS:
          this.permissionsMenu.push({
            path: '/assignments',
            name: element.permission,
            icon: 'bi bi-briefcase',
          });
          break;
        /*case permissionConstant.ASSIGNMENTS:
          this.permissionsMenu.push({
            path: '/assignments',
            name: element.permission,
            icon: 'bi bi-briefcase',
          });
          break;*/

        case permissionConstant.REPORTS:
          this.permissionsMenu.push({
            path: '/reports/payroll',
            name: element.permission,
            icon: 'bi bi-pie-chart',
          });
          break;
      }
    });

    if (this.user.role_uuid === roleConstant.AGENCY) {
      this.permissionsMenu.push({
        path: '/reports/payroll',
        name: 'Reports',
        icon: 'bi bi-pie-chart',
      });

      this.permissionsMenu.push({
        path: '/invoices',
        name: 'Invoices',
        icon: 'bi bi-cash-stack',
      });
    }

    // if(this.user.role_uuid === roleConstant.CANDIDATE || this.user.role_uuid === roleConstant.CLIENT){
    //   this.permissionsMenu.push({
    //     path: '/tasks-list',
    //     name: 'Tasks',
    //     icon: 'bi bi-list-task',
    //   });
    // }

    const haveSettingsPermission = this.dataSharedService.permissionData.find((x: any) => x.permission === permissionConstant.SETTINGS);
    if (haveSettingsPermission) {
      this.permissionsMenu.push({
        path: '/settings',
        name: haveSettingsPermission.permission,
        icon: 'bi bi-gear-fill',
      });
    }
  }

  toggleCollapse(): void {
    this.visible = !this.visible;
  }

  showDropdown() {
    this.showDropUp = !this.showDropUp;
  }

  showSubMenuDropdown(subMenu: any) {
    if (subMenu == 'reportSubMenu') {
      this.showReportSubmenu = !this.showReportSubmenu;
    }
  }
}