<div class="modal-header">
  <mat-icon class="clickable text-transparent">&nbsp;&nbsp;&nbsp;&nbsp;</mat-icon>
  <h4 class="modal-title">Add New Candidate</h4>
  <mat-icon class="close clickable me-4" (click)="onClose()">close</mat-icon>
</div>

<form class="" [formGroup]="userForm" (ngSubmit)="onSubmit()">
  <div class="modal-body">
      <div class="row g-3">
      <div class="col-md-6">
        <label for="form-label">First Name</label>
        <input type="text" class="form-control" formControlName="first_name"
          [id]="userForm.controls.first_name.errors && userForm.controls.first_name.touched?'invalidField':''">
      </div>
      <div class="col-md-6">
        <label for="form-label">Last Name</label>
        <input type="text" class="form-control" formControlName="last_name"
          [id]="userForm.controls.last_name.errors && userForm.controls.last_name.touched?'invalidField':''">
      </div>
      </div>
      <div class="row g-3">
      <div class="col-md-6">
        <label for="form-label">Email</label>
        <input type="text" class="form-control" formControlName="email"
          [id]="userForm.controls.email.errors && userForm.controls.email.touched?'invalidField':''">
      </div>
      <div class="col-md-6">
        <label for="form-label">Identifier</label>
        <input type="text" class="form-control" formControlName="identifier"
          [id]="''">
      </div>
      </div>
      <div class="row g-3">
      <div class="col-md-6">
        <label for="form-label">SSN/NI</label>
        <input type="text" class="form-control" formControlName="ssn"
          [id]="''">
      </div>
      <div class="col-md-6">
        <label for="form-label">PO Number</label>
        <input type="text" class="form-control" formControlName="po_number"
          [id]="''">
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Address 1</label>
          <input type="text" class="form-control" formControlName="address_1"
            [id]="userForm.controls.address_1.errors && userForm.controls.address_1.touched?'invalidField':''">
        </div>
        <div class="col-md-6">
          <label for="form-label">Address 2</label>
          <input type="text" class="form-control" formControlName="address_2"
            [id]="''">
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Zip Code</label>
          <input type="text" class="form-control" formControlName="post_zip_code"
            [id]="''">
        </div>
        <div class="col-md-6">
          <label for="form-label">Country</label>
          <input type="text" class="form-control" formControlName="country"
            [id]="''">
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Bank Name</label>
          <input type="text" class="form-control" formControlName="bank_name"
            [id]="">
        </div>
        <div class="col-md-6">
          <label for="form-label">Account Number</label>
          <input type="text" class="form-control" formControlName="account_number"
            [id]="">
        </div>
      </div>
      <div class="row g-3">
        <div class="col-md-6">
          <label for="form-label">Payment Terms</label>
          <mat-select class="dropdown" type="text"  
          class="form-control" formControlName="payment_terms">
            <mat-option [value]="'7'">7 Days</mat-option>
            <mat-option [value]="'15'">15 Days</mat-option>
            <mat-option [value]="'30'">30 Days</mat-option>
            <mat-option [value]="'45'">45 Days</mat-option>
            <mat-option [value]="'60'">60 Days</mat-option>
            <mat-option [value]="'90'">90 Days</mat-option>
          </mat-select>
        </div>
      </div>
      <div class="error" *ngIf="error">
        <span>{{error}}</span>
      </div>
      </div>
  </div>
  <div class="modal-footer">
    <button type="submit" class="btn btn-sm mx-2 btn-primary" [disabled]="!userForm.valid">Save</button>
  </div>
</form>