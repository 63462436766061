import { Component, OnInit } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import { CreateUserComponent } from '../components/create-user/create-user.component';
import { AuthenticationService } from '../services/authentication.service';
import { DataSharedService } from '../services/data-shared.service';
import { TimesheetService } from '../services/timesheet.service';
import { UserService } from '../services/user.service';
import { SuccessModalComponent } from '../components/success-modal/success-modal.component';
import { SuccessModal } from '../app.model';
import { MatDialog } from '@angular/material/dialog';
import { AccountGenerationLinkModalComponent } from '../components/account-generation-link-modal/account-generation-link-modal.component';

@Component({
  selector: 'app-candidates',
  templateUrl: './candidates.component.html',
  styleUrls: ['./candidates.component.css']
})
export class CandidatesComponent implements OnInit {
  timesheets: any = [];
  candidates: any = [];
  allCandidates: any = [];
  loading: boolean = false;
  redirectedName!: string;
  totalCount: number = 0;
  candidateSearch: string = '';

  constructor(
    private timesheetService: TimesheetService,
    public authService: AuthenticationService,
    public dataSharedService: DataSharedService,
    public userService: UserService,
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private activeModalService: NgbActiveModal,
    public dialog: MatDialog,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }


  onAdd() {
    const modalRef = this.modalService.open(CreateUserComponent, {
      size: 'lg',
      modalDialogClass: 'add-candidate-modal',
      backdrop: "static",
      keyboard: false,
      centered: true,
    });
    modalRef.result.then((data) => {
      if (data?.refresh === true) {
        this.dataSharedService.sendUpdate('Update Active Candidates');
      }
    });
  }
}
