import { Component, OnInit, Input } from '@angular/core';
import { DataSharedService } from '../services/data-shared.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal, NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { SuccessModal } from '../app.model';
import { SuccessModalComponent } from '../components/success-modal/success-modal.component';
import { InvoicesService } from '../services/invoices.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-invoice-details',
  templateUrl: './invoice-details.component.html',
  styleUrls: ['./invoice-details.component.css']
})
export class InvoiceDetailsComponent implements OnInit {

  @Input() public invoice: any;
  @Input() public invoiceType: string;
  invoiceDetails: FormGroup | any;
  candidateTrackingHrs: any = localStorage.getItem('candidateTrackingHrs') ? localStorage.getItem('candidateTrackingHrs') : 1; 


  constructor(
    private activeModalService: NgbActiveModal,
    public dataSharedService: DataSharedService,
    private modalService: NgbModal,
    public invoiceService: InvoicesService,
    public router: Router,
  ) { }

  ngOnInit(): void {
    console.log(this.candidateTrackingHrs);
    this.invoiceDetails = new FormGroup({
      first_name: new FormControl('', Validators.required),
      last_name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      identifier: new FormControl('', []),
      ssn: new FormControl('', []),
    });
  }

  onClose() {
    this.activeModalService.close();
  }

  onUpdate(invoiceType : any) {
    console.log('onUpdate', invoiceType);
  }

  onSend(invoiceType : any, invoiceId : any) {
    console.log('onApprove', invoiceType, invoiceId);
    const payload = {
      'invoice_type' : invoiceType,
    }
    this.invoiceService.sendInvoice(invoiceId, payload).subscribe({
      next: (response) => {
        this.successModal('Invoice Sent Successfully.');
        this.activeModalService.close();
        this.reloadComponent(false, invoiceType == "client" 
                                    ? 'invoices/bill-invoices' 
                                    : 'invoices/pay-invoices');
      },
      error: (err) => {
      }});
  }

  reloadComponent(self:boolean,urlToNavigateTo ?:string){
    //skipLocationChange:true means dont update the url to / when navigating
   console.log("Current route I am on:",this.router.url);
   const url=self ? this.router.url :urlToNavigateTo;
   this.router.navigateByUrl('/',{skipLocationChange:true}).then(()=>{
     this.router.navigate([`/${url}`]).then(()=>{
       console.log(`After navigation I am on:${this.router.url}`)
     })
   })
 }

  successModal(message: string) {
    const modalRef = this.modalService.open(SuccessModalComponent, {
      size: 'sm',
      modalDialogClass: 'success-modal',
      backdrop: false,
    });
    let data: SuccessModal = {
      message: message,
      image: 'assets/img/checkmark.png',
      duration: 2000,
    }
    modalRef.componentInstance.data = data;
    modalRef.result.then((data) => {
      if (data?.action === 'yes') {
        this.activeModalService.close();
      }
    });
  }

}
