import { Component, OnInit } from '@angular/core';
import { InvoicesService } from '../services/invoices.service';
import { invoiceStatusConstant } from '../app.constant';
import { ActivatedRoute, Router } from '@angular/router';


@Component({
  selector: 'app-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.css']
})
export class InvoicesComponent implements OnInit {


  constructor(
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    
  }
}
