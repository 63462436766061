import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { apiConstant } from '../app.constant';
import { HttpClient } from '@angular/common/http';
import { Invoices } from '../app.model';



@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  public baseUrl = `${environment.baseUrl}`;
  constructor(private http: HttpClient) { }

  getInvoices(page: number, filters: any = []) {
    let apiUrl = this.baseUrl+apiConstant.INVOICES+'?page='+page;
    if(filters.active != undefined) {
      apiUrl+= '&filters[active]='+filters.active;
    }

    return this.http.get<Invoices>(apiUrl);
  }

  sendInvoice(invoiceId: any, payload: any) {
    let apiUrl = this.baseUrl+ invoiceId + '/'+ apiConstant.SEND_INVOICE;
    
    return this.http.post<Invoices>(apiUrl , payload)
  }
}
