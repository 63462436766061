<div class="container-fluid">
    <div class="border-bottom pt-6">
        <div class="row align-items-center">
            <div class="col-sm col-12">
                <h1 class="h2 ls-tight">Invoices</h1>
            </div>
        </div>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/invoices/pay-invoices">Pay</a>
            </li>
            <li class="nav-item">
                <a class="nav-link" routerLinkActive="active" routerLink="/invoices/bill-invoices">Bill</a>
            </li>
        </ul>
        <router-outlet></router-outlet>
    </div>
</div>