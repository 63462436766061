import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbActiveModal, NgbModalRef} from '@ng-bootstrap/ng-bootstrap';
import { invoiceStatusConstant } from '../../app.constant';
import { InvoicesService } from '../../services/invoices.service';
import { InvoicesParams } from '../../app.model';
import { InvoiceDetailsComponent } from '../../invoice-details/invoice-details.component';



@Component({
  selector: 'app-pay-invoices',
  templateUrl: './pay-invoices.component.html',
  styleUrls: ['./pay-invoices.component.css']
})
export class PayInvoicesComponent implements OnInit {

  public loading: boolean = false;
  public invoices: any = [];
  totalCount: number = 0;
  private modalRef: NgbModalRef;
  public INVOICE_STATUS = invoiceStatusConstant;

  constructor(
    public invoicesService: InvoicesService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(page = 1): void {
    const params: InvoicesParams = {
      limit: 20,
      page,
      start_date: '',
      end_date: '',
      status: '',
      order_by: '',
      order: '',
    };
    this.invoices = [];
    this.loading = true;
    this.invoicesService.getInvoices(page, params).subscribe((invoices: any) => { 
      this.loading = false;
      this.invoices = invoices.data;
      this.totalCount = invoices.meta.total;
    });
  }

  
  onStepChange(event: any) {
    this.ngOnInit(event)
  }

  viewInvoiceDetails(invoice: any, invoiceType: string) {
    console.log(invoice);
    this.modalRef = this.modalService.open(InvoiceDetailsComponent, {
      size: 'lg',
      centered: true,
      keyboard: false,
      backdrop: "static",
      modalDialogClass: 'update-client-modal',
    });
    this.modalRef.componentInstance.invoice = invoice;
    this.modalRef.componentInstance.invoiceType = invoiceType;
  }

}
