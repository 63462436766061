import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { JobsComponent } from './jobs/jobs.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TimesheetsComponent } from './timesheets/timesheets.component';
import { SettingsComponent } from './settings/settings.component';
import { ReportsComponent } from './reports/reports.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token-interceptor.interceptor';
import { LogoutComponent } from './logout/logout.component';
import { ErrorInterceptor } from './interceptors/error.interceptor';
import { ClientsComponent } from './clients/clients.component';
import { JobAssignmentsComponent } from './job-assignments/job-assignments.component';
import { ModalComponent } from './components/modal/modal.component';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TimesheetDetailsComponent } from './timesheet-details/timesheet-details.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatIcon, MatIconModule } from '@angular/material/icon';
import { MatTableModule } from '@angular/material/table';
import { MatSortModule } from '@angular/material/sort';
import { MaterialModule } from './material/material.module';
import { MatDialog, MatDialogModule, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CreateFormComponent } from './components/create-form/create-form.component';
import { DatePipe } from '@angular/common';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { AddReminderComponent } from './components/add-reminder/add-reminder.component';
import { RemindersComponent } from './reminders/reminders.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SubmitTimesheetComponent } from './submit-timesheet/submit-timesheet.component';
import { TimesheetFormsComponent } from './timesheet-forms/timesheet-forms.component';
import { AgenciesComponent } from './agencies/agencies.component';
import { CreateAgencyComponent } from './components/create-agency/create-agency.component';
import { GeneralTabComponent } from './general-tab/general-tab.component';
import { AuthorizationFlowTabComponent } from './authorization-flow-tab/authorization-flow-tab.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { CandidatesComponent } from './candidates/candidates.component';
import { CreateUserComponent } from './components/create-user/create-user.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { AuditTrailComponent } from './components/audit-trail/audit-trail.component';
import { CreateTimesheetComponent } from './components/create-timesheet/create-timesheet.component';
import { ApiPaginatorComponent } from './components/api-paginator/api-paginator.component';
import { CreateJobAssignmentComponent } from './components/create-job-assignment/create-job-assignment.component';
import { CreateJobComponent } from './components/create-job/create-job.component';
import { AlertModalComponent } from './components/alert-modal/alert-modal.component';
import { CreateClientComponent } from './components/create-client/create-client.component';
import { SearchSelectComponent } from './components/search-select/search-select.component';
import { CommentsComponent } from './components/comments/comments.component';
import { DateFormatComponent } from './components/date-format/date-format.component';
import { NgxUsefulSwiperModule } from 'ngx-useful-swiper';
import { SocialLoginModule, SocialAuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider } from "angularx-social-login";
import { CandidateAssignmentsComponent } from './components/candidate-assignments/candidate-assignments.component';
import { NotificationPanelComponent } from './components/notification-panel/notification-panel.component';
import { AttachmentsComponent } from './components/attachments/attachments.component';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { SuccessModalComponent } from './components/success-modal/success-modal.component';
import { TasksComponent } from './components/tasks/tasks.component';
import { TasksListComponent } from './components/tasks-list/tasks-list.component';
import { ClientContactsComponent } from './client-contacts/client-contacts.component';
import { SendEmailComponent } from './components/send-email/send-email.component';
import { TimesheetApprovalLinkModalComponent } from './components/timesheet-approval-link-modal/timesheet-approval-link-modal.component';
import { AccountGenerationLinkModalComponent } from './components/account-generation-link-modal/account-generation-link-modal.component';
import { TimesheetViewLinkModalComponent } from './components/timesheet-view-link-modal/timesheet-view-link-modal.component';
import { PayBillReportComponent } from './pay-bill-report/pay-bill-report.component';
import { EditJobAssignmentComponent } from './components/edit-job-assignment/edit-job-assignment.component';
import { EditTimesheetComponent } from './components/edit-timesheet/edit-timesheet.component';
import { PayrollReportComponent } from './payroll-report/payroll-report.component';
import { EditClientComponent } from './components/edit-client/edit-client.component';
import { SelectSearchComponent } from './components/select-search/select-search.component';
import { ClientCreateComponent } from './client-create/client-create.component';
import { ClientUpdateComponent } from './client-update/client-update.component';
import { MissingTimesheetsComponent } from './missing-timesheets/missing-timesheets.component';
import { AwaitingApprovalTimesheetsComponent } from './awaiting-approval-timesheets/awaiting-approval-timesheets.component';
import { BoardComponent } from './board/board.component';
import { MisssingTimesheetsModalComponent } from './components/misssing-timesheets-modal/misssing-timesheets-modal.component';
import { AwaitingApprovalsModalComponent } from './components/awaiting-approvals-modal/awaiting-approvals-modal.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ActiveCandidatesComponent } from './components/active-candidates/active-candidates.component';
import { InactiveCandidatesComponent } from './components/inactive-candidates/inactive-candidates.component';
import { AddEndEmploymentDateComponent } from './components/add-end-employment-date/add-end-employment-date.component';
import { EditJobComponent } from './components/edit-job/edit-job.component';
import { ActiveJobsComponent } from './components/active-jobs/active-jobs.component';
import { InactiveJobsComponent } from './components/inactive-jobs/inactive-jobs.component';
import { ActiveAssignmentsComponent } from './components/active-assignments/active-assignments.component';
import { InactiveAssignmentsComponent } from './components/inactive-assignments/inactive-assignments.component';
import { CreateOnBoardingFormComponent } from './components/create-on-boarding-form/create-on-boarding-form.component';
import { JobAssignmentRequestComponent } from './components/job-assignment-request/job-assignment-request.component';
import { SubmitOnboardingFormDetailsComponent } from './components/submit-onboarding-form-details/submit-onboarding-form-details.component';
import { RequestJobsComponent } from './components/request-jobs/request-jobs.component';
import { ViewOnBoardingFormSubmitDetailsComponent } from './components/view-on-boarding-form-submit-details/view-on-boarding-form-submit-details.component';
import { EditOnBoardingFormComponent } from './components/edit-on-boarding-form/edit-on-boarding-form.component';
import { ViewOnBoardingFormComponent } from './components/view-on-boarding-form/view-on-boarding-form.component';
import { JobRejectReasonComponent } from './components/job-reject-reason/job-reject-reason.component';
import { ViewJobRejectReasonComponent } from './components/view-job-reject-reason/view-job-reject-reason.component';
import { EditCandidateComponent } from './components/edit-candidate/edit-candidate.component';
import { OvertimeSettingsComponent } from './components/overtime-settings/overtime-settings.component';
import { CurrencyFormatComponent } from './components/currency-format/currency-format.component';
import { InvoicesComponent } from './invoices/invoices.component';
import { InvoiceDetailsComponent } from './invoice-details/invoice-details.component';
import { PayInvoicesComponent } from './components/pay-invoices/pay-invoices.component';
import { BillInvoicesComponent } from './components/bill-invoices/bill-invoices.component';


const CLIENT_ID = '537840536900-najbt3srfc3fgpa7nhg28ul0tjtkks7e.apps.googleusercontent.com';
const googleLoginOptions = {
  scope: 'profile email',
  plugin_name: 'clug_login' //can be any name
};

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    DashboardComponent,
    LoginComponent,
    HomeComponent,
    JobsComponent,
    TimesheetsComponent,
    SettingsComponent,
    ReportsComponent,
    LogoutComponent,
    ClientsComponent,
    JobAssignmentsComponent,
    ModalComponent,
    TimesheetDetailsComponent,
    CreateFormComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    AddReminderComponent,
    RemindersComponent,
    ChangePasswordComponent,
    SubmitTimesheetComponent,
    TimesheetFormsComponent,
    AgenciesComponent,
    CreateAgencyComponent,
    GeneralTabComponent,
    AuthorizationFlowTabComponent,
    PaginatorComponent,
    CandidatesComponent,
    CreateUserComponent,
    AuditTrailComponent,
    CreateTimesheetComponent,
    ApiPaginatorComponent,
    CreateJobAssignmentComponent,
    CreateJobComponent,
    AlertModalComponent,
    CreateClientComponent,
    SearchSelectComponent,
    CommentsComponent,
    DateFormatComponent,
    CandidateAssignmentsComponent,
    NotificationPanelComponent,
    AttachmentsComponent,
    SuccessModalComponent,
    TasksComponent,
    TasksListComponent,
    ClientContactsComponent,
    SendEmailComponent,
    TimesheetApprovalLinkModalComponent,
    PayBillReportComponent,
    AccountGenerationLinkModalComponent,
    TimesheetViewLinkModalComponent,
    EditJobAssignmentComponent,
    EditTimesheetComponent,
    PayrollReportComponent,
    EditClientComponent,
    SelectSearchComponent,
    ClientCreateComponent,
    ClientUpdateComponent,
    MissingTimesheetsComponent,
    AwaitingApprovalTimesheetsComponent,
    BoardComponent,
    MisssingTimesheetsModalComponent,
    AwaitingApprovalsModalComponent,
    ActiveCandidatesComponent,
    InactiveCandidatesComponent,
    AddEndEmploymentDateComponent,
    OvertimeSettingsComponent,
    EditJobComponent,
    ActiveJobsComponent,
    InactiveJobsComponent,
    ActiveAssignmentsComponent,
    InactiveAssignmentsComponent,
    CreateOnBoardingFormComponent,
    JobAssignmentRequestComponent,
    SubmitOnboardingFormDetailsComponent,
    RequestJobsComponent,
    ViewOnBoardingFormSubmitDetailsComponent,
    EditOnBoardingFormComponent,
    ViewOnBoardingFormComponent,
    JobRejectReasonComponent,
    ViewJobRejectReasonComponent,
    EditCandidateComponent,
    CurrencyFormatComponent,
    InvoicesComponent,
    InvoiceDetailsComponent,
    PayInvoicesComponent,
    BillInvoicesComponent,
  ],
  imports: [
    ImageCropperModule,
    MatDialogModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    FormsModule,
    NgbModule,
    NgApexchartsModule,
    BrowserAnimationsModule,
    DragDropModule,
    MatIconModule,
    MaterialModule,
    NgxUsefulSwiperModule,
    SocialLoginModule,
    NgxMaterialTimepickerModule,
    MatDatepickerModule, MatMomentDateModule
  ],
  providers: [
    NgbActiveModal,
    DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // MatDialog,
    // MatDialogRef
    {
      provide: MatDialogRef,
      useValue: {}
    },
    {
      provide: MAT_DIALOG_DATA,
      useValue: {}
    },
    {
      provide: "SocialAuthServiceConfig",
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              CLIENT_ID,
              googleLoginOptions
            )
          }
        ]
      } as SocialAuthServiceConfig
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }